nav {
  border-bottom: 3px solid white;
  font-weight: 600;
  height: 60px;
  background-color: #0e287c;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav a {
  font-size: 1.7vh;
  color: #fff;
  text-decoration: none;
  margin-right: 15px;
}

.dropdown ul li a {
  padding-left: 10px;
  font-size: 1.5vh;
  color: #ffffff;
}

.dropdown ul li a:hover {
  padding-left: 10px;
  color: orange;
}

.dropdown ul {
  padding-bottom: 5px;
  display: none;
  position: absolute;
  background-color: #0e287c;
  min-width: 100px;
  z-index: 1000;
}

.dropdown span {
  font-size: 1.7vh;
  margin-right: 15px;
  cursor: pointer;
}

.dropdown span:hover {
  color: orange;
}


.dropdown:hover ul {
  display: block;
}

.dropdown.active ul {
  display: block;
}

.logo img {
  border-radius: 10px !important;

}

.logo {
  margin-left: 1%;
  margin-right: 2%;
}

nav a:hover {
  color: orange;
}

ul {
  list-style-type: none;
  margin-left: auto;
  margin-right: auto;
  /* Centra horizontalmente */
  padding: 0;
  display: flex;
  justify-content: center;
  /* Centra los elementos dentro del contenedor */
}

li {
  margin-right: 10px;
}

.active-link {
  color: orange;
}

.social {
  display: flex;
  align-items: center;
}

.social a {
  color: white;
  font-size: 20px;
  margin-left: 10px;
}

.menu-icon {
  display: none;
  cursor: pointer;
  color: white;
  font-size: 8vw;
  position: absolute;
  right: 20px;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
    margin-bottom: 1%;
    font-size: x-large;
  }

  nav {
    width: auto;
    height: 30px;
  }

  .logo {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 10%;
    left: 25px;
  }

  ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 33px;
    right: 0;
    left: auto;
    background-color: #0e287c;
    z-index: 1;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }

  ul.active {
    display: flex;
    z-index: 999;
  }

  li {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .social {
    margin-right: 40%;
  }

  .social a {
    margin-left: 0;
  }

  .dropdown ul {
    width: 100%;
    margin-right: 100%;
  }
}