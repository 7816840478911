.car-class {
  background-image: url('https://images.unsplash.com/photo-1486262715619-67b85e0b08d3?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
  padding: 20px;
  height: 800px;
  position: relative;
  overflow: hidden;
  
  /* Asegura que el contenido esté por encima de la máscara azul */
}

.table-title {
font-size: 2vw;
  display: flex;
  align-items: center;
  justify-content: center; 
  padding-bottom: 50px!important; /* Espacio entre el título y la tabla */
  margin-bottom: 20px; /* Espacio entre el título y la tabla */
}

.table-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;    
}

.car-content p{
  margin-top: 3%;
  right: 5%;
  font-size: small;
  width: 30%;
  position: absolute;
  z-index: 999;
  color: white;
}

thead {
  color: orange;
}
.table-car h4{
  text-align: center;
}
.table-car {
  margin-top: 6%;
  color: white;
border-radius: 15px;
margin-left: auto;
margin-right: auto;
width: 60%;
padding-top: 3%;
padding-bottom: 3%;
background: rgba(171, 171, 172, 0.562);
/* Green background with 30% opacity */
position: relative;
z-index: 2;


}



.table-wrapper {
  overflow-x: auto; /* Añade desplazamiento horizontal si es necesario */
}

.blue-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(6, 1, 59, 0.568);
  z-index: 0;
}



.table-car th {
  font-size: xx-large;
  padding-bottom: 1vh;
  padding-left: 8vh;
  padding-right: 8vh;
  text-align: center;
}

.table-car td {
  font-weight: 500;
  text-align: center;
}

@media (max-width: 768px) {
.table-title {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 80%;
  font-size: 4.2vw;
  margin-bottom: -7%;
}

.car-class{
  height: 600px;

}
.table-car th {
  
  font-size:medium;
  padding-bottom: 1vh;
  padding-left: 4vh;
  padding-right: 4vh;
  text-align: center;
}

.table-car td {
font-size: small;
} 
.table-car {
width: 100%;
}

.car-content p{
  right: 0%;
  margin-top: 14%;
  font-size: small;
  width: 95%;
  position: absolute;
  z-index: 999;
  color: white;
}
}