.main-home {
    background-color: rgb(255, 255, 255);
    width: auto;

}

.home-title {
    background-color: orange;
    color: rgb(245, 245, 245);
    text-align: center;
    border: orange 1px solid;
    width: auto;
}

.home-title h3 {
    border: 1px solid orange;
    background-color: orange;
    z-index: 999;
    animation: forwards;
    font-size: 1.9vw;
}

.upper-image {
    background-image: url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
    background-position: unset;
    height: 35vw;
    width: auto;
}

.bottom-data {
    margin-top: 5%;
    color: #373737;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 90%;
    margin-bottom: 5%;
}

.description {
    margin-right: 5%;
    margin-left: 1%;
    width: 40%;
}



.objetives {
    border-left: 2px solid #b4b4b4;
    width: 60%;
}

.objetives div{
    margin-left: 5%;
}

.specific-objetive p{
    margin-left: 4%;
}

img{
    margin-top: 4%!important;
}

@media (max-width: 768px) {
    .home-title h3 {
        font-size: 3.5vw;
    }

    .upper-image {
        height: 55vw;
        background-position:top;

    }

    .bottom-data {
        font-size:2.8vw;
        margin-top: 5%;
        color: #6b6b6b;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column; /* Cambio a dirección de columna en dispositivos pequeños */
        width: 90%;
        margin-bottom: 5%;
    }
    
    .description {
        margin-right: 0; /* Eliminar el margen derecho en dispositivos pequeños */
        margin-bottom: 5%; /* Espaciado adicional entre description y objectives */
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial;
        width: 100%; /* Ancho completo en dispositivos pequeños */
    }
    
    .objetives {
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial;
        border-left: none; /* Eliminar el borde izquierdo en dispositivos pequeños */
        border-top: 2px solid #c7c7c7; /* Agregar un borde superior en dispositivos pequeños */
        width: 100%; /* Ancho completo en dispositivos pequeños */
    }
    
    .objetives div {
        margin-left: 0; /* Eliminar el margen izquierdo en dispositivos pequeños */
    }
    
    img{
        width: 60%!important;
        height: 25%!important;
    }

}