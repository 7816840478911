/* Estilos generales */
.spotify-class {
    position: relative;
    background-image: url('https://images.unsplash.com/photo-1478737270239-2f02b77fc618?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
    background-position: center;
    height: 90vh;
    margin-bottom: 1%;
}

.spotify-class::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(6, 1, 59, 0.568);
    z-index: 0; /* Mover la máscara detrás del contenido */
}

.title, .spotify-class p {
    position: relative;
    z-index: 1; /* Asegurar que el contenido esté sobre la máscara */
}

.spotify-frame {
    position: relative; /* Asegurar que el z-index funcione correctamente */
    z-index: 999; /* Asegurar que el elemento esté por encima */
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    border-left: 6px solid #1db954;
    border-right: 6px solid #1db954;
}

.spotify-class i {
    color: #1db954;
    margin-top: 0.2%;
    font-size: 350%;
    text-decoration: none!important;
}

.title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.title a {
    color: #1db954;
    font-size: 200%;
    text-decoration: none!important;
}
  
.spotify-class p {
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    color: white;
    width: 40%;
    text-align: justify;
    font-size: large;
    text-align: center;
}

@media (max-width: 768px) {
    .title a{
        font-size: 2vh;
    }

    .spotify-class i{
        font-size: 230%;
    }
    .slick-slide iframe {
        position: relative;
        height: 300px; 
    }
    .spotify-frame {
        margin-top: 12%;
        border: none;
        width: 80%;
    }
    .spotify-class p {
        width: 80%;
        font-size: 3vw;
        padding-bottom: 9%;
        border-top: 2px solid #1db954;
        padding-top: 9%;

        border-bottom: 2px solid #1db954;
    }

    .spotify-class{
        height: 120vh;
    }


}
